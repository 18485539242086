import React, {useContext} from 'react';
import { useState } from 'react';
import styled from "styled-components";
import {I18nContext, T} from '@deity-io/falcon-i18n';
import { Link } from 'react-router-dom';
import DesktopResponsive from "../NewsletterMobileBanner/DesktopResponsive";

import './NewsletterSlider.scss';

const Animation = styled.div`
  transform: ${({ animate }) => (animate ? 'translateX(0%)' : 'translateX(-400px)')};
  transition: transform 0.5s;
  opacity: 1;
`;

const NewsletterSlider = () => {
    const [isOpen, setOpen] = useState(false);
    const toggleModal = () => setOpen(prevState => !prevState);
    const { t } = useContext(I18nContext);

    function Content(props) {
        return <Animation animate={props.isOpen}>
            <div className={`newsletterSlideoutContent ${
                !isOpen ? "newsletterSlideoutContent-closed" : "newsletterSlideoutContent-opened"
            }`}>
                    <div className="slideoutImage"></div>
                    <p className="newsletterSignUp">
                        <T id="newsletterSlider.newsletterSignUp" />
                        <span className="boldText">
                            <T id="newsletterSlider.boldText" />
                        </span>
                    </p>
                    <p className="newsletterSignUpSmall">
                        <T id="newsletterSlider.newsletterSignUpSmall" />
                    </p>
                    <p className="newsletterSignUpButton">
                        <Link to={`/${t('newsletterSlider.newsletterLink')}/`}>
                            <button className="action" onClick={toggleModal}>
                                <T id="newsletterSlider.signUpButton" />
                            </button>
                        </Link>
                    </p>
            </div>
        </Animation>;
    }

    if (DesktopResponsive().isDesktop) {
        return (
            <div className="newsletterSlideout">
                <Content isOpen={isOpen} />
                <button type="button" onClick={toggleModal} className={`action newsletterSlideoutButton ${
                    !isOpen ? "newsletterSlideoutButton-closed" : "newsletterSlideoutButton-opened"
                }`}>
                <span className="buttonText">
                    <T id="newsletterSlider.buttonText" />
                </span>
                </button>
            </div>
        );
    }
        return null;


};

export default NewsletterSlider;
